export const state = () => ({
  tutorials: {
    loading: false,
    items: [],
    paging: {
      total: 0,
      page: 1,
    },
  },

  tutorialVideo: {
    loading: false,
    item: {},
  },
})

export const getters = {
  tutorials: state => state.tutorials,
  tutorialVideo: state => state.tutorialVideo,
}

export const mutations = {
  setTutorialVideos(state, tutorials) {
    state.tutorials = tutorials
  },

  setTutorialVideo(state, tutorialVideo) {
    state.tutorialVideo = tutorialVideo
  },
}

export const actions = {
  async getCreativeTutorialsVideos({ commit }, params) {
    commit('setTutorialVideos', {
      loading: true,
      items: []
    })

    const { result, error } = await this.$request('/ads-videos', {
      params: this.$getParams(params),
      customUrl: process.env.freeToolUrl,
    })

    if (error) {
      commit('setTutorialVideos', {
        loading: false,
      })
      return
    }
    commit('setTutorialVideos', {
      loading: false,
      items: result.result.content,
      paging: result.result.paging,
    })
  },

  async getCreativeTutorialsVideo({ commit }, params) {
    commit('setTutorialVideo', {
      loading: true,
    })

    const { result, error } = await this.$request(`/ads-videos/${params.slug}`, {
      customUrl: process.env.freeToolUrl,
    })

    if (error) {
      commit('setTutorialVideo', {
        loading: false,
      })
      return
    }

    commit('setTutorialVideo', {
      loading: false,
      item: result,
    })
  },
}
