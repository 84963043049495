
import AppMixin from '~/mixins/App'
import { setCookie } from '~/helpers'
export default {
  name: 'DefaultIndex',
  mixins: [AppMixin],

  computed: {
    languages() {
      return this.$i18n.locales
    },
  },

  mounted() {
    setCookie(this.$route)
  },

  methods: {
    onChangeLanguage(locale) {
      this.$i18n.setLocale(locale)
    },
  },
}
