// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../static/fonts/ProximaNovaA-Medium.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Proxima\";src:local(\"Proxima\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\")}.container-body[data-v-079d058e]{font-family:\"Proxima\";padding:0}@media screen and (max-width:575px){.container-body[data-v-079d058e]{max-width:100%;padding:0 16px}}@media screen and (min-width:576px){.container-body[data-v-079d058e]{margin-left:auto;margin-right:auto;max-width:540px}}@media screen and (min-width:768px){.container-body[data-v-079d058e]{margin-left:auto;margin-right:auto;max-width:735px}}@media screen and (min-width:1024px){.container-body[data-v-079d058e]{margin-left:auto;margin-right:auto;max-width:992px}}@media screen and (min-width:1200px){.container-body[data-v-079d058e]{margin-left:auto;margin-right:auto;max-width:1140px}}.bg-purple[data-v-079d058e]{background-color:#6e44ff}.text-purple[data-v-079d058e]{color:#6e44ff}.font-32[data-v-079d058e]{font-size:32px;line-height:38px}.font-40[data-v-079d058e]{font-size:40px;line-height:48px}.font-56[data-v-079d058e]{font-size:56px;line-height:64px}.font-64[data-v-079d058e]{font-size:64px;line-height:72px}.top-0[data-v-079d058e]{top:0!important}.right-0[data-v-079d058e]{right:0!important}.left-0[data-v-079d058e]{left:0!important}.hidden[data-v-079d058e]{overflow:hidden}.icon-x[data-v-079d058e]{margin:0 32px}.icon-x img[data-v-079d058e]{width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
