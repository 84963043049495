export const state = () => ({
    sideMenu: false,
    notification: {
      enable: false,
      message: '',
      type: 'info',
      timeout: 5000,
    },
  })
  export const mutations = {
    toggleSideMenu(state, value) {
      state.sideMenu = value
    },
  
    setNotification(state, notification) {
      state.notification = notification
    },
  }
  
  export const actions = {
    FB_parse() {
      setTimeout(() => {
        if (window && window.FB) {
          window.FB.XFBML.parse()
        }
      }, 500)
    },
  }
