export const Menu = () => import('../../components/menu/Menu.vue' /* webpackChunkName: "components/menu" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/common/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const CircleLoading = () => import('../../components/common/CircleLoading.vue' /* webpackChunkName: "components/circle-loading" */).then(c => wrapFunctional(c.default || c))
export const Collapse = () => import('../../components/common/Collapse.vue' /* webpackChunkName: "components/collapse" */).then(c => wrapFunctional(c.default || c))
export const Comment = () => import('../../components/common/Comment.vue' /* webpackChunkName: "components/comment" */).then(c => wrapFunctional(c.default || c))
export const CustomImage = () => import('../../components/common/CustomImage.vue' /* webpackChunkName: "components/custom-image" */).then(c => wrapFunctional(c.default || c))
export const Input = () => import('../../components/common/Input.vue' /* webpackChunkName: "components/input" */).then(c => wrapFunctional(c.default || c))
export const Tab = () => import('../../components/common/Tab.vue' /* webpackChunkName: "components/tab" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../components/common/Tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const Video = () => import('../../components/common/Video.vue' /* webpackChunkName: "components/video" */).then(c => wrapFunctional(c.default || c))
export const VideoEmbeb = () => import('../../components/common/VideoEmbeb.vue' /* webpackChunkName: "components/video-embeb" */).then(c => wrapFunctional(c.default || c))
export const ButtonLanguage = () => import('../../components/language/ButtonLanguage.vue' /* webpackChunkName: "components/button-language" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../components/banner/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const Benefits = () => import('../../components/benefits/Benefits.vue' /* webpackChunkName: "components/benefits" */).then(c => wrapFunctional(c.default || c))
export const CaseStudy = () => import('../../components/case-study/CaseStudy.vue' /* webpackChunkName: "components/case-study" */).then(c => wrapFunctional(c.default || c))
export const Conversation = () => import('../../components/conversation/Conversation.vue' /* webpackChunkName: "components/conversation" */).then(c => wrapFunctional(c.default || c))
export const FeedBack = () => import('../../components/feed-back/FeedBack.vue' /* webpackChunkName: "components/feed-back" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/footer/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/header/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const Information = () => import('../../components/information/Information.vue' /* webpackChunkName: "components/information" */).then(c => wrapFunctional(c.default || c))
export const Integrations = () => import('../../components/integrations/Integrations.vue' /* webpackChunkName: "components/integrations" */).then(c => wrapFunctional(c.default || c))
export const SideBar = () => import('../../components/side-bar/SideBar.vue' /* webpackChunkName: "components/side-bar" */).then(c => wrapFunctional(c.default || c))
export const Slider = () => import('../../components/slider/Slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))
export const WorkingOn = () => import('../../components/working-on/WorkingOn.vue' /* webpackChunkName: "components/working-on" */).then(c => wrapFunctional(c.default || c))
export const BannerPlatform = () => import('../../components/ecomdy-platform/BannerPlatform.vue' /* webpackChunkName: "components/banner-platform" */).then(c => wrapFunctional(c.default || c))
export const CtaPlatform = () => import('../../components/ecomdy-platform/CtaPlatform.vue' /* webpackChunkName: "components/cta-platform" */).then(c => wrapFunctional(c.default || c))
export const KeyResultPlatform = () => import('../../components/ecomdy-platform/KeyResultPlatform.vue' /* webpackChunkName: "components/key-result-platform" */).then(c => wrapFunctional(c.default || c))
export const ObjectivePlatform = () => import('../../components/ecomdy-platform/ObjectivePlatform.vue' /* webpackChunkName: "components/objective-platform" */).then(c => wrapFunctional(c.default || c))
export const ResultPlatform = () => import('../../components/ecomdy-platform/ResultPlatform.vue' /* webpackChunkName: "components/result-platform" */).then(c => wrapFunctional(c.default || c))
export const SolutionPlatform = () => import('../../components/ecomdy-platform/SolutionPlatform.vue' /* webpackChunkName: "components/solution-platform" */).then(c => wrapFunctional(c.default || c))
export const BannerPartnership = () => import('../../components/partnership/BannerPartnership.vue' /* webpackChunkName: "components/banner-partnership" */).then(c => wrapFunctional(c.default || c))
export const ComparisonPartnership = () => import('../../components/partnership/ComparisonPartnership.vue' /* webpackChunkName: "components/comparison-partnership" */).then(c => wrapFunctional(c.default || c))
export const FormJoin = () => import('../../components/partnership/FormJoin.vue' /* webpackChunkName: "components/form-join" */).then(c => wrapFunctional(c.default || c))
export const BannerWhiteLabel = () => import('../../components/white-label/BannerWhiteLabel.vue' /* webpackChunkName: "components/banner-white-label" */).then(c => wrapFunctional(c.default || c))
export const BecomeTiktokAgency = () => import('../../components/white-label/BecomeTiktokAgency.vue' /* webpackChunkName: "components/become-tiktok-agency" */).then(c => wrapFunctional(c.default || c))
export const EcomdySupport = () => import('../../components/white-label/EcomdySupport.vue' /* webpackChunkName: "components/ecomdy-support" */).then(c => wrapFunctional(c.default || c))
export const FormJoinPartner = () => import('../../components/white-label/FormJoinPartner.vue' /* webpackChunkName: "components/form-join-partner" */).then(c => wrapFunctional(c.default || c))
export const BannerStrategicPartner = () => import('../../components/strategic-partner/BannerStrategicPartner.vue' /* webpackChunkName: "components/banner-strategic-partner" */).then(c => wrapFunctional(c.default || c))
export const ConditionStrategicPartner = () => import('../../components/strategic-partner/ConditionStrategicPartner.vue' /* webpackChunkName: "components/condition-strategic-partner" */).then(c => wrapFunctional(c.default || c))
export const StrategicProgram = () => import('../../components/strategic-partner/StrategicProgram.vue' /* webpackChunkName: "components/strategic-program" */).then(c => wrapFunctional(c.default || c))
export const BannerCareers = () => import('../../components/company-careers/BannerCareers.vue' /* webpackChunkName: "components/banner-careers" */).then(c => wrapFunctional(c.default || c))
export const BlogCareers = () => import('../../components/company-careers/BlogCareers.vue' /* webpackChunkName: "components/blog-careers" */).then(c => wrapFunctional(c.default || c))
export const GreatBenefits = () => import('../../components/company-careers/GreatBenefits.vue' /* webpackChunkName: "components/great-benefits" */).then(c => wrapFunctional(c.default || c))
export const InformationCareers = () => import('../../components/company-careers/InformationCareers.vue' /* webpackChunkName: "components/information-careers" */).then(c => wrapFunctional(c.default || c))
export const InformationJobDetail = () => import('../../components/company-careers/InformationJobDetail.vue' /* webpackChunkName: "components/information-job-detail" */).then(c => wrapFunctional(c.default || c))
export const OpenJobs = () => import('../../components/company-careers/OpenJobs.vue' /* webpackChunkName: "components/open-jobs" */).then(c => wrapFunctional(c.default || c))
export const AboutEcomdy = () => import('../../components/company-about-us/AboutEcomdy.vue' /* webpackChunkName: "components/about-ecomdy" */).then(c => wrapFunctional(c.default || c))
export const AutomatedSolution = () => import('../../components/company-about-us/AutomatedSolution.vue' /* webpackChunkName: "components/automated-solution" */).then(c => wrapFunctional(c.default || c))
export const BannerAboutUs = () => import('../../components/company-about-us/BannerAboutUs.vue' /* webpackChunkName: "components/banner-about-us" */).then(c => wrapFunctional(c.default || c))
export const Statements = () => import('../../components/company-about-us/Statements.vue' /* webpackChunkName: "components/statements" */).then(c => wrapFunctional(c.default || c))
export const StoryNameEcomdy = () => import('../../components/company-about-us/StoryNameEcomdy.vue' /* webpackChunkName: "components/story-name-ecomdy" */).then(c => wrapFunctional(c.default || c))
export const BenefitsPolicy = () => import('../../components/terms-policy/BenefitsPolicy.vue' /* webpackChunkName: "components/benefits-policy" */).then(c => wrapFunctional(c.default || c))
export const FinePolicy = () => import('../../components/terms-policy/FinePolicy.vue' /* webpackChunkName: "components/fine-policy" */).then(c => wrapFunctional(c.default || c))
export const Introduction = () => import('../../components/terms-policy/Introduction.vue' /* webpackChunkName: "components/introduction" */).then(c => wrapFunctional(c.default || c))
export const PricingStructure = () => import('../../components/terms-policy/PricingStructure.vue' /* webpackChunkName: "components/pricing-structure" */).then(c => wrapFunctional(c.default || c))
export const PrivacyPolicy = () => import('../../components/terms-policy/PrivacyPolicy.vue' /* webpackChunkName: "components/privacy-policy" */).then(c => wrapFunctional(c.default || c))
export const RefundPolicy = () => import('../../components/terms-policy/RefundPolicy.vue' /* webpackChunkName: "components/refund-policy" */).then(c => wrapFunctional(c.default || c))
export const ServiceFee = () => import('../../components/terms-policy/ServiceFee.vue' /* webpackChunkName: "components/service-fee" */).then(c => wrapFunctional(c.default || c))
export const ShippingPolicy = () => import('../../components/terms-policy/ShippingPolicy.vue' /* webpackChunkName: "components/shipping-policy" */).then(c => wrapFunctional(c.default || c))
export const TermsOfService = () => import('../../components/terms-policy/TermsOfService.vue' /* webpackChunkName: "components/terms-of-service" */).then(c => wrapFunctional(c.default || c))
export const BannerCaseStudy = () => import('../../components/resource-case-study/BannerCaseStudy.vue' /* webpackChunkName: "components/banner-case-study" */).then(c => wrapFunctional(c.default || c))
export const BannerTiktokShop = () => import('../../components/tiktok-shop/BannerTiktokShop.vue' /* webpackChunkName: "components/banner-tiktok-shop" */).then(c => wrapFunctional(c.default || c))
export const Comparison = () => import('../../components/tiktok-shop/Comparison.vue' /* webpackChunkName: "components/comparison" */).then(c => wrapFunctional(c.default || c))
export const ServicePackages = () => import('../../components/tiktok-shop/ServicePackages.vue' /* webpackChunkName: "components/service-packages" */).then(c => wrapFunctional(c.default || c))
export const BannerCreativeVideo = () => import('../../components/creative-video/BannerCreativeVideo.vue' /* webpackChunkName: "components/banner-creative-video" */).then(c => wrapFunctional(c.default || c))
export const NoData = () => import('../../components/creative-video/NoData.vue' /* webpackChunkName: "components/no-data" */).then(c => wrapFunctional(c.default || c))
export const RelatedVideos = () => import('../../components/creative-video/RelatedVideos.vue' /* webpackChunkName: "components/related-videos" */).then(c => wrapFunctional(c.default || c))
export const PartnerItem = () => import('../../components/partner/PartnerItem.vue' /* webpackChunkName: "components/partner-item" */).then(c => wrapFunctional(c.default || c))
export const BannerEcomdyAirwallex = () => import('../../components/ecomdy-airwallex/BannerEcomdyAirwallex.vue' /* webpackChunkName: "components/banner-ecomdy-airwallex" */).then(c => wrapFunctional(c.default || c))
export const ReasonChoose = () => import('../../components/ecomdy-airwallex/ReasonChoose.vue' /* webpackChunkName: "components/reason-choose" */).then(c => wrapFunctional(c.default || c))
export const PrivacyPolicyPixel = () => import('../../components/pixel-policy/PrivacyPolicyPixel.vue' /* webpackChunkName: "components/privacy-policy-pixel" */).then(c => wrapFunctional(c.default || c))
export const Awards = () => import('../../components/company-our-team/Awards.vue' /* webpackChunkName: "components/awards" */).then(c => wrapFunctional(c.default || c))
export const BannerOurTeam = () => import('../../components/company-our-team/BannerOurTeam.vue' /* webpackChunkName: "components/banner-our-team" */).then(c => wrapFunctional(c.default || c))
export const CardName = () => import('../../components/company-our-team/CardName.vue' /* webpackChunkName: "components/card-name" */).then(c => wrapFunctional(c.default || c))
export const EventsOurTeam = () => import('../../components/company-our-team/EventsOurTeam.vue' /* webpackChunkName: "components/events-our-team" */).then(c => wrapFunctional(c.default || c))
export const OurTeam = () => import('../../components/company-our-team/OurTeam.vue' /* webpackChunkName: "components/our-team" */).then(c => wrapFunctional(c.default || c))
export const OurTeamTabs = () => import('../../components/company-our-team/OurTeamTabs.vue' /* webpackChunkName: "components/our-team-tabs" */).then(c => wrapFunctional(c.default || c))
export const BannerStripe = () => import('../../components/stripe/BannerStripe.vue' /* webpackChunkName: "components/banner-stripe" */).then(c => wrapFunctional(c.default || c))
export const FormStripe = () => import('../../components/stripe/FormStripe.vue' /* webpackChunkName: "components/form-stripe" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
