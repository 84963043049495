const convertDate = (time) => {
  if (!time) return ''
  const date = new Date(time)
  let month = date.getMonth()

  switch (month) {
    case 0:
      month = 'Jan'
      break

    case 1:
      month = 'Feb'
      break

    case 2:
      month = 'Mar'
      break

    case 3:
      month = 'Apr'
      break

    case 4:
      month = 'May'
      break

    case 5:
      month = 'Jun'
      break

    case 6:
      month = 'Jul'
      break

    case 7:
      month = 'Aug'
      break

    case 8:
      month = 'Sep'
      break

    case 9:
      month = 'Oct'
      break

    case 10:
      month = 'Nov'
      break

    case 11:
      month = 'Dec'
      break

    default:
      break
  }

  return `${month} ${date.getDate()}, ${date.getFullYear()}`
}

const truncateText = (text, number) => {
  if (!text || !number) return ''
  if (text.length < number) return text
  return `${text.substring(0, number)}...`
}

const validateFile = (file) => {
  if(file.size > process.env.fileAttachmentSize) {
    return false
  }
  if(file.type !== 'application/pdf' && file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/png') {
    return false
  }
  return true
}

const setCookie = (route) => {
  if(Object.keys(route.query).length !== 0) {
    document.cookie = `query=${route.fullPath.replace(route.path, '')}`
  }
}

const getCookie = (cookieName) => {
  const name = cookieName + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const listCookie = decodedCookie.split(';')
  for (let i = 0; i < listCookie.length; i++) {
    let cookie = listCookie[i]
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1)
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length)
    }
  }
  return ''
}

export { convertDate, truncateText, validateFile, setCookie, getCookie }
