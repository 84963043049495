export default {
  computed: {
    HeaderBlack() {
      return (
        this.$route.path === '/coming-soon' ||
        this.$route.path === '/partnership' ||
        this.$route.path === '/white-label' ||
        this.$route.path === '/strategic-partner' ||
        this.$route.path === '/creative-video' ||
        this.$route.path === '/terms-policy' ||
        this.$route.path === '/partners' ||
        this.$route.path === '/terms/terms-of-services' ||
        this.$route.path === '/pixel-policy' ||
        this.$route.path === '/stripe-partner' ||
        this.$route.path === '/stripe-partner/success'
      )
    },
    HeaderAirwallex() {
      return (
        this.$route.path === '/ecomdy-airwallex' ||
        this.$route.path === '/ch/ecomdy-airwallex'
      )
    },
    pixelPolicyPage() {
      return (
        this.$route.path === '/pixel-policy' ||
        this.$route.path === '/home-pixel' ||
        this.$route.path === '/about-ecomdy-pixel'
      )
    },
    hideHeader() {
      return (
        this.$route.path === '/maintenance'
      )
    },
  },
}
