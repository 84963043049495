export default ({ app }, inject) => {
  /*
      - GET request  params: optional
        const { results, error }  = await this.$request('url', { params} )

      - POST request with payload
        const { results, error }  = await this.$request('url', {
          options: {
              method: 'POST',
              payload: {
                field_name: field_data,
              },
            },
          })
      */

  inject('request', async (url, data) => {
    const urlRequest = data.customUrl
      ? `${data.customUrl}${url}`
      : `${process.env.baseUrl}${url}`
    const { $axios } = app
    try {
      let options = { method: 'GET' }
      if (data?.params) {
        options = {
          method: 'GET',
          params: { ...data.params },
        }
      }

      if (data?.options) {
        options = {
          method: data.options.method,
          data: { ...data.options.payload },
        }
      }

      const response = await $axios(urlRequest, options)
      return {
        result: response.data,
        error: null,
      }
    } catch (error) {
      /* eslint no-console: ["error", { allow: ["error"] }] */
      console.error(url, error)
      return {
        result: null,
        error: error.response.data,
      }
    }
  })

  inject('getParams', (params) => {
    return {
      page: 1,
      size: 12,
      ...params,
    }
  })
}
