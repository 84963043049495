export default function ({ req, $axios, redirect, store }) {
    if (req && req.headers) {
      $axios.setHeader('origin', `https://${req.headers.host}`)
    }

    $axios.onError((error) => {
      if (error.response.status === 404) {
        store.commit('app/setNotification', {
          type: 'error',
          enable: true,
          message: 'Server error',
        })
      }
    })
  }
  