export const state = () => ({
  fileData: {
    loading: false,
    file: '',
  },
})

export const getters = {
  fileData: state => state.fileData,
  fileUrl: state => state.fileData.file.imageUrl,
}

export const mutations = {
  uploadFileCV(state, payload) {
    state.fileData = payload
  },
}

export const actions = {

  async uploadFile({ commit }, file) {
    commit('uploadFileCV', {
      loading: true,
      file: '',
    })

    const formData = new FormData();
    formData.append('file', file);

    try {
      const data = await this.$axios.post(`https://dev-upload-api.ecomdy.com/api/upload/2wdKZVdP79ZhbOcvV7lR8JPfokPmH77V`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        // timeout: 1000,
      });
      const { result } = data.data;

      commit('uploadFileCV', {
        loading: false,
        file: result,
      })
      return data;

    } catch (error) {
      commit('uploadFileCV', {
        loading: false,
        file: '',
      })
      return error;
    }
  },
}
